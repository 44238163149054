import React, { ReactElement } from 'react'
import './MissionVision.scss'
import { graphql } from 'gatsby'
import Seo from '../../components/Seo/Seo'

import loadable from '@loadable/component'
const Layout = loadable(() => import('../../components/Layout/Layout'))

type MissionVisionPropTypes = {
  data: {
    missionVision: {
      id: string
      slug: string
      vision: {
        title: string
        body: {
          value: string
        }[]
      }
      mission: {
        title: string
        body: {
          value: string
        }[]
      }
    }
  }
}

const MissionVision = ({
  data: { missionVision },
}: MissionVisionPropTypes): ReactElement => {
  return (
    <Layout>
      <Seo
        title="Mission & Vision | Amaia Land | Affordable House and Lot & Condos in the Philippines"
        ogMetaData={{
          description: 'Amaia Land Mission & Vision',
        }}
      />

      <div className="mission-vision">
        <div className="mission-vision-content">
          <div className="mission">
            <h1 className="title">{missionVision?.mission?.title}</h1>
            <div
              className="content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: missionVision?.mission.body[0]?.value,
              }}
            />
          </div>
          <h1 className="title">{missionVision?.vision?.title}</h1>
          <div className="vision">
            <div
              className="content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: missionVision?.vision.body[0]?.value,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MissionVision

export const pageQuery = graphql`
  query MissionVisionQuery {
    missionVision {
      ...MissionVisionFields
    }
  }
`
